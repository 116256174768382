<template>
    <div>
        <!-- logo -->
        <div class="banner container-fuild text-center">相关介绍</div>
        <div class="container">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-xs-12 col-sm-12 col-md-6 content  wow zoomIn">
                        <div class="content-block">
                            <h2 :id="id">
                            {{articleTitle}}
                            <small>/ 作者:{{nickName}} ，发布时间：{{time |formatDate()}}，  浏览量:{{reading}}</small>
                            </h2>
                            <div v-html="articleContext"></div>
                        </div>
                </div>
            </div>
        </div>
        <!-- 这里是页脚 -->
        <Footer />
    </div>
</template>

<script>
import Header from '../components/hearder.vue'
import Footer from '../components/footer.vue'
import { get } from '../network/request'
import {formatDate} from '../network/cldate.js'
export default {
    data() {
        return {
            id:'',
            articleTitle:'',
            articleContext:'', //内容
            nickName:'',
            time:'',
            reading:''
        }
    },
    filters:{
      formatDate(time) {
        time = time * 1000
        let date = new Date(time)
        // console.log(new Date(time))
        return formatDate(date, 'yyyy-MM-dd')
      }
    },
    created(){
        this.id = this.$route.query.id
        // console.log(this.id);
    },
    mounted() {
        this.getlist(this.id)
    },
    methods: {
        getlist(id){
            get('/ess/ess-article/get/' + id,{

            }).then(res=>{
                this.articleTitle = res.data.data.articleTitle
                this.nickName = res.data.data.nickName
                this.reading = res.data.data.reading
                this.articleContext = res.data.data.articleContext
                this.time = res.data.data.gmtCreate
                console.log(res.data);
            }).catch(err=>{
                console.log(err);
            })
        }
    },
    components:{
        Header,
        Footer,
        }
        
}

</script>

<style lang="less" scoped>

.banner {
  color: #fff;
  font-size: 30px;
  height: 150px;
  line-height: 150px;
  background-image: url("../assets/img/banner1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
}
.content-block {
  margin: 40px 0;
}
.content-block > h2 {
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}
.content-block > div {
  font-size: 18px;
  line-height: 30px;
    min-height: 330px;
}
.container{
    width:80%;
    align-items: center;
    border-radius: 4px;
    margin-top: 10px;
    .xian{
        margin-bottom: 1px;
    }
}

</style>